<template>
  <v-layout column v-if="Productos != null" fill-height>
    <v-app-bar fixed :style="movil ? 'top:53px' : 'top:64px'">
      <v-spacer></v-spacer>
      <v-btn
        :text="'All' != categoria"
        mx-auto
        :color="'All' == categoria ? 'primary' : 'black'"
        @click="setCategoria(categoria)"
        v-text="'Todos'"
      ></v-btn>
      <v-btn
        v-for="(item, index) in categorias"
        :key="index"
        :text="item != categoria"
        mx-auto
        :color="item == categoria ? 'primary' : 'black'"
        @click="setCategoria(item)"
        v-text="item"
      ></v-btn>
      <v-spacer></v-spacer>
    </v-app-bar>
    <Header
      titulo=""
      imagen="https://www.ofimania.com/img/Header/Productos.webp"
      :style="movil ? 'margin-top:53px' : 'margin-top:64px'"
    />
    <Paginacion Productos :array="GetProductos" :slice="24" />
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import Header from "../components/Header.vue";
import Separador from "../components/Separador.vue";
import CProductos from "../components/CProductos.vue";
import Paginacion from "../components/Paginacion.vue";

export default {
  metaInfo: {
    title: "Productos | Ofimania",
  },
  components: {
    Header,
    Separador,
    CProductos,
    Paginacion,
  },
  created() {
    this.$store.state.cargando = true;
    if (this.Productos == null) {
      this.axios
        .get("https://www.ofimania.com/API/Producto/Get")
        .then((response) => {
          this.$store.state.Productos = response.data;
          this.$store.state.cargando = false;
        })
        .catch((error) => {
          this.$store.state.cargando = false;
          this.$store.state.alerta = {
            estado: true,
            tipo: "error",
            titulo: "Error de Conexion",
            info: "Verifique su Conexion a Internet",
          };
        });
    } else {
      this.$store.state.cargando = false;
    }
  },
  data() {
    return {
      categoria: "All",
      categorias: [
        "Arte y Dibujo",
        "Boutique",
        "Electrodomésticos",
        "Escolar",
        "Novedades",
        "Oficina",
        "Tecnología",
      ],
    };
  },
  methods: {
    setCategoria(categoria) {
      if (categoria == this.categoria) this.categoria = "All";
      else this.categoria = categoria;
    },
  },
  computed: {
    ...mapState(["Productos", "movil"]),
    GetProductos() {
      if (this.categoria == "All") return this.Productos;
      return this.Productos.filter((item) => {
        return item.categoria == this.categoria;
      });
    },
  },
};
</script>
